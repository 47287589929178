import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`iframe`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://whatis.techtarget.com/definition/IFrame-Inline-Frame"
        }}>{`https://whatis.techtarget.com/definition/IFrame-Inline-Frame`}</a></li>
    </ul>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/embed-code",
        "title": "embed code"
      }}>{`embed code`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      